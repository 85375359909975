<template>
  <div class="dots-wrapper">
    <div class="dots-container">
      <span class="dot" v-for="dot in 3" :key="dot"></span>

      <div class="collections">
        <button @click.stop="openModal">Создать</button>

        <div v-if="isModalVisible" class="modal-overlay" @click="closeModal">
          <div class="modal-content" @click.stop>
            <h3>Добавить коллекцию</h3>
            <input v-model="newCollectionTitle" type="text" placeholder="Название коллекции"/>

            <input
                ref="fileInput"
                type="file"
                accept="image/*"
                @change="handleFileChange"
                class="file-input"
            />

            <button @click="createCollection">Создать</button>
            <button @click="closeModal">Отмена</button>
          </div>
        </div>

        <div
            v-for="collection in userCollections"
            :key="collection.id"
            class="collection-item"
            @click.stop="toggle(collection.id)"
        >
          <span
              class="plus-icon"
              :class="{ 'checked-icon': isSongInCollection(collection.id) }"
          ></span>
          {{ collection.title }}
        </div>
      </div>
    </div>
    <Snackbar :color="'red'" :show="showError" :message="errorMessage" @update:show="showError = $event"/>
    <Snackbar :color="'green'" :show="showSuccess" :message="successMessage" @update:show="showSuccess = $event"/>
  </div>
</template>

<script lang="ts">
import {defineComponent} from 'vue';
import {components} from 'backend-types';
import collectionApi from "@/api/CollectionApi";
import Snackbar from "@/components/common/Snackbar.vue";
import {VBtn, VCard, VCardActions, VCardText, VCardTitle, VDialog, VForm, VTextField} from 'vuetify/components';

type GetUserCollectionResponse = components['schemas']['GetUserCollectionResponse'];
type CreateUserCollectionRequest = components["schemas"]["CreateUserCollectionRequest"]

export default defineComponent({
  components: {
    Snackbar,
    VDialog,
    VCard,
    VCardTitle,
    VCardText,
    VCardActions,
    VForm,
    VTextField,
    VBtn,
  },
  props: {
    songId: {
      type: String,
      required: true,
    },
    userCollections: {
      type: Array as () => GetUserCollectionResponse[],
      required: true,
    },
    songCollections: {
      type: Array as () => string[],
      required: true,
    },
  },
  emits: ['toggleCollection'],
  data() {
    return {
      isModalVisible: false,
      newCollectionTitle: '',
      coverImage: null as File | null,
      showError: false,
      showSuccess: false,
      errorMessage: '',
      successMessage: '',
    };
  },
  methods: {
    toggle(collectionId: string) {
      this.$emit('toggleCollection', collectionId, this.songId);
    },
    isSongInCollection(collectionId: string) {
      return this.songCollections.includes(collectionId);
    },
    openModal(event: MouseEvent) {
      event.stopPropagation();
      this.isModalVisible = true;
    },
    closeModal() {
      this.isModalVisible = false;
      this.newCollectionTitle = '';
      this.coverImage = null;
      if (this.$refs.fileInput) {
        (this.$refs.fileInput as HTMLInputElement).value = '';
      }
    },
    handleFileChange(event: Event) {
      const target = event.target as HTMLInputElement;
      if (target.files && target.files.length > 0) {
        this.coverImage = target.files[0];
      }
    },
    async createCollection() {
      if (this.newCollectionTitle.trim() && this.coverImage) {
        try {
          const newCollection = await collectionApi.createCollection(this.coverImage, this.newCollectionTitle);

          this.successMessage = 'Коллекция успешно создана!';
          this.showSuccess = true;

          this.userCollections.push(newCollection);
          this.closeModal();
        } catch (error) {
          console.error("Ошибка при создании коллекции:", error);

          this.errorMessage = 'Не удалось создать коллекцию. Попробуйте еще раз.';
          this.showError = true;
        }
      } else {
        this.errorMessage = 'Пожалуйста, введите название коллекции и выберите изображение';
        this.showError = true;
      }
    },
  },
});
</script>

<style scoped>
.dots-wrapper {
  display: none;
}

.dots-container {
  display: flex;
  align-items: center;
  position: relative;
  padding: 10px;
  transition: transform 0.3s;
}

.dots-container:hover {
  transform: scale(1.1);
}

.dot {
  width: 5px;
  height: 5px;
  background-color: black;
  border-radius: 50%;
  margin: 0 2px;
  transition: background-color 0.3s;
}

.dots-container:hover .dot {
  background-color: rgba(255, 126, 7, 1);
}

.collections {
  position: absolute;
  top: 4%;
  transform: translateX(-100%);
  background-color: black;
  color: white;
  border-radius: 5px;
  padding: 40%;
  z-index: 1;
  display: none;
  width: max-content;
  min-width: 200px;
  max-width: 500px;
}

.dots-container:hover .collections {
  display: block;
}

.collection-item {
  display: flex;
  align-items: center;
  padding: 5px 20px;
  cursor: pointer;
  transition: transform 0.3s, background-color 0.3s;
}

.collection-item:hover {
  transform: scale(1.1);
  background-color: rgba(255, 126, 7, 1);
}

.plus-icon {
  color: white;
  margin-right: 10px;
  cursor: pointer;
  transition: content 0.3s;
  display: inline-block;
}

.plus-icon.checked-icon::before {
  content: '\2713';
}

.plus-icon::before {
  content: '\002B';
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  transition: opacity 0.3s;
}

.modal-content {
  background-color: black;
  color: white;
  padding: 20px;
  border-radius: 5px;
  text-align: center;
  width: 250px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.3);
  transition: transform 0.3s ease;
}

.modal-content h3 {
  margin-bottom: 15px;
}

input {
  margin: 10px 0;
  padding: 8px;
  width: 100%;
  border-radius: 5px;
  border: 1px solid rgba(255, 126, 7, 1);
  background-color: black;
  color: white;
  text-align: center;
  font-size: 14px;
}

button {
  padding: 8px 15px;
  cursor: pointer;
  border: none;
  border-radius: 5px;
  background-color: rgba(255, 126, 7, 1);
  color: white;
  font-size: 14px;
  margin-top: 10px;
  width: 100%;
  transition: background-color 0.3s;
}

button:hover {
  background-color: rgba(255, 126, 7, 0.9);
}

button:active {
  background-color: rgba(255, 126, 7, 0.8);
}

button + button {
  background-color: #aaa;
}

button + button:hover {
  background-color: #888;
}

.file-input {
  margin: 10px 0;
  padding: 8px;
  width: 100%;
  border-radius: 5px;
  border: 1px solid rgba(255, 126, 7, 1);
  background-color: black;
  color: white;
  font-size: 14px;
}
</style>
