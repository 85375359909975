import axios, { AxiosError, AxiosInstance, AxiosResponse } from 'axios';
import { CookieManager } from './CookieManager';

const API_URL = '/api';

export type ApiErrorResponse = { message: string; exceptionCode: string };

interface ApiClientOptions {
    baseURL?: string;
    timeout?: number;
    headers?: Record<string, string>;
}

export class BaseApi {
    private axiosInstance: AxiosInstance;
    private failedQueue: any[] = [];
    private isRefreshing = false;

    constructor({ baseURL = API_URL, timeout = 10000, headers = {} }: ApiClientOptions = {}) {
        this.axiosInstance = axios.create({ baseURL, timeout, headers, withCredentials: true });

        this.axiosInstance.interceptors.request.use(
            (config) => {
                const token = CookieManager.getCookie('token');
                if (token) config.headers['Authorization'] = `Bearer ${token}`;
                return config;
            },
            (error) => Promise.reject(error)
        );

        this.axiosInstance.interceptors.response.use(
            (response) => response,
            this.handleResponseError
        );
    }

    private handleResponseError = async (error: AxiosError<ApiErrorResponse>) => {
        if (error.response?.status === 401) {
            CookieManager.deleteCookie('token');
            window.location.reload();
            console.error('Unauthorized. Token has been removed')
        }
        console.error('API Error:', error.response?.data?.exceptionCode, error.response?.data?.message || error.message);
        return Promise.reject(error);
    };

    private processQueue(error: any, token: string | null = null) {
        this.failedQueue.forEach((prom) => (error ? prom.reject(error) : prom.resolve(token)));
        this.failedQueue = [];
    }

    private async refreshToken() {
        const refreshToken = CookieManager.getCookie('refreshToken');
        if (!refreshToken) throw new Error('No refresh token found');

        const { data } = await this.axiosInstance.post('/user/refresh-token', { refreshToken });
        CookieManager.setCookie('token', data.token);
        return data.token;
    }

    public async get<T>(url: string, config = {}): Promise<T> {
        const { data } = await this.axiosInstance.get<T>(url, config);
        return data;
    }

    public async post<T>(url: string, data: any, config = {}): Promise<T> {
        const { data: responseData } = await this.axiosInstance.post<T>(url, data, config);
        return responseData;
    }

    public async put<T>(url: string, data: any, config = {}): Promise<T> {
        const { data: responseData } = await this.axiosInstance.put<T>(url, data, config);
        return responseData;
    }

    public async delete<T>(url: string, config = {}): Promise<T> {
        const { data } = await this.axiosInstance.delete<T>(url, config);
        return data;
    }
}
