<template>
  <footer class="FooterWrapper">
    <div class="FooterTopRow">
      <h3>Играй. Учись. Вдохновляй.</h3>
    </div>

    <div class="FooterContent">
      <div class="FooterColumn LogoColumn">
        <router-link to="/" class="LogoLink">
        <img src="@/assets/icons/ico/logo.ico" alt="Логотип" class="FooterLogo" />
        </router-link>
      </div>

      <div class="FooterColumn LinksColumn">
        <ul>
          <li>
            <router-link to="/songsLibrary" class="Footer-link">
              <div class="fourteen-font">Песни</div>
            </router-link>
          </li>
          <li>
            <router-link to="/allGenres" class="Footer-link">
              <div class="fourteen-font">Жанры</div>
            </router-link>
          </li>
          <li>
            <router-link to="/allMusicians" class="Footer-link">
              <div class="fourteen-font">Исполнители</div>
            </router-link>
          </li>
          <li>
            <router-link to="/chordLibrary" class="Footer-link">
              <div class="fourteen-font">Аккорды</div>
            </router-link>
          </li>
          <li>
            <router-link to="/upload-chords" class="Footer-link">
              <div class="fourteen-font">Добавить аккорды</div>
            </router-link>
          </li>
          <li>
            <router-link to="/tuner" class="Footer-link">
              <div class="fourteen-font">Тюнер</div>
            </router-link>
          </li>
        </ul>
      </div>

      <div class="FooterColumn ContactsColumn">
        <div class="fourteen-font">Telegram: <a href="https://t.me/fretflex.online" target="_blank" class="Footer-link orange-link">@fretflex.online</a>
        </div>
        <div class="fourteen-font">Email: <a href="mailto:box@fretflex.ru" class="Footer-link orange-link">box@fretflex.ru</a></div>
        <div class="SocialLinks">
          <a href="#" class="Footer-link">Spotify</a>
          <a href="#" class="Footer-link">Яндекс.Музыка</a>
          <a href="#" class="Footer-link">VK Музыка</a>
          <a href="#" class="Footer-link">Instagram</a>
          <a href="#" class="Footer-link">Youtube</a>
          <a href="#" class="Footer-link">Вконтакте</a>
        </div>
      </div>

      <div class="FooterColumn SubscriptionColumn">
        <div class="fourteen-font">Стань частью Fretflex - каждый аккорд, каждый шаг вместе с тобой!</div>
        <form @submit.prevent="subscribe">
          <input type="text" v-model="name" placeholder="Имя" required />
          <input type="email" v-model="email" placeholder="E-mail" required />
          <button type="submit">Подписаться</button>
        </form>
        <div class="fourteen-font">
          Подписываясь на рассылку, вы соглашаетесь с
          <a href="#" class="Footer-link">Правилами пользования</a> и <a href="#" class="Footer-link">Политикой конфиденциальности</a>
        </div>
      </div>
    </div>

    <div class="FooterBottomRow">
      <a href="#" class="Footer-link">Политика конфиденциальности</a>
      <a href="#" class="Footer-link">Политика модерации</a>
      <a href="#" class="Footer-link">Пользовательское соглашение</a>
      <a href="#" class="Footer-link">Правила использования</a>
      <a href="#" class="Footer-link">Правила авторского права</a>
      <a href="#" class="Footer-link">Правила сообщества</a>
    </div>
  </footer>
</template>

<script>
export default {
  name: "Footer",
  data() {
    return {
      name: "",
      email: "",
    };
  },
  methods: {
    subscribe() {
      alert(`Спасибо за подписку, ${this.name}!`);
      this.name = "";
      this.email = "";
    },
  },
};
</script>

<style scoped>
.FooterWrapper {
  background-color: #111;
  color: #fff;
  padding: 20px;
  font-size: 14px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.FooterTopRow {
  text-align: center;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  padding-bottom: 10px;
}

.FooterContent {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.FooterColumn {
  padding: 10px;
}

.LogoColumn {
  display: flex;
  justify-content: center;
  align-items: center;
}

.FooterLogo {
  max-width: 100px;
}

.LinksColumn ul {
  list-style: none;
  padding: 0;
}

.LinksColumn li {
  margin-bottom: 10px;
}

.Footer-link {
  text-decoration: none;
  color: white;
  transition: color 0.3s ease;
}

.Footer-link:hover {
  color: rgba(255, 126, 7, 1);
}

.ContactsColumn a {
  color: white;
  text-decoration: none;
  transition: color 0.3s ease;
}

.ContactsColumn a:hover {
  color: rgba(255, 126, 7, 1);
}

.ContactsColumn .orange-link {
  color: rgba(255, 126, 7, 1);
}

.SocialLinks a {
  text-decoration: none;
  color: white;
  margin-right: 10px;
  transition: color 0.3s ease;
}

.SocialLinks a:hover {
  color: rgba(255, 126, 7, 1);
}

.SubscriptionColumn form {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.SubscriptionColumn input {
  padding: 10px;
  border: 1px solid #fff;
  border-radius: 5px;
  background-color: transparent;
  color: #fff;
}

.SubscriptionColumn button {
  padding: 10px;
  border: none;
  border-radius: 5px;
  background-color: rgba(255, 126, 7, 1);
  color: #fff;
  cursor: pointer;
  transition: background-color 0.3s;
}

.SubscriptionColumn button:hover {
  background-color: rgba(255, 126, 7, 1);
}

.FooterBottomRow {
  text-align: center;
}

.FooterBottomRow a {
  color: white;
  margin: 0 5px;
  text-decoration: none;
  transition: color 0.3s ease;
}

.FooterBottomRow a:hover {
  color: rgba(255, 126, 7, 1);
}

.SocialLinks a {
  text-decoration: none;
  color: white;
  display: block;
  margin-bottom: 5px;
  transition: color 0.3s ease;
}

.SocialLinks a:hover {
  color: rgba(255, 126, 7, 1);
}

</style>
